.editor-note-wrapper {
	display: flex;
	flex-direction: column;
	background-color: #feff9c;
	background-colorx: #F5D76E;
	background-colorx: #009688;
	opacityx: 0.5;
	padding: 1em 1em 1em 1em;
	margin: 1em 15% 1em 15%;
	margin-top: 1em;
	borderx: .2em dashed #707070;
	border-radius: 1em;
}

.editor-note-controls {
	display: flex;
	flex-direction: row;
}

div.ql-editor.ql-blank::before {
	/* font-size: 50px; */
	/* color:white !important; */
}

div.editor-light {

	color: black !important;


}

div.editor-dark {

	color: #E5E4E2 !important;

}






div.editor-light .blot-lineitem-button {
	color: #787878;
	border: 1px solid #787878;

}

div.editor-dark .blot-lineitem-button {
	color: darkgray;
	border: 1px solid darkgray;

}

.ql-editor>p {
	text-indent: 1.25em !important;
	margin-bottom: 1em !important;
	font-size: 1.5em !important;
	/* padding-left: 2em !important; */
	/* padding-right: 2em !important; */
}

/* .ql-editor.ql-blank::before {
	color:yellow;
} */

.editing-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.treeNodeTitle:hover {
	color: black;
}


@media only screen and (max-width: 1024px) {
	.tab-content {}

	.writing-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.editor .ql-editor {
		font-size: 14px;
		/* color: red; */
		overflow-y: visible;
		height: auto;
		padding: 0px .5em 0px .5em;
		margin-left: .5em;
		margin-right: .5em;
		border-topx: dashed 1px rgba(0, 150, 136, .2);
	}

	.blurbs-content {
		width: 100%;
	}

	div.editor-light .ql-editor.ql-blank::before {
		/* font-size: 50px; */
		color: #787878 !important;
		font-size: 1.5em !important;
		text-indent: 1.25em !important;
	
	}
	
	div.editor-dark .ql-editor.ql-blank::before {
		/* font-size: 50px; */
		color: darkgray !important;
		font-size: 1.5em !important;
		text-indent: 1.25em !important;
	
	}



}

@media only screen and (min-width: 1025px) {
	.tab-content {
		margin-left: 2em;
		margin-right: 2em;
		margin-topx: 2em;
	}

	.writing-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 10%;
		margin-right: 10%;
	}

	.editor .ql-editor {
		font-size: 14px;
		/* color: rgba(0, 0, 0, 0.7); */
		overflow-y: visible;
		height: auto;
		padding: 0px .5em 0px .5em;
		margin-leftx: 5em;
		margin-rightx: 5em;
		border-topx: dashed 1px rgba(0, 150, 136, .2);
	}

	.blurbs-content {
		width: 75%;
	}

	.blot-wrapper {
		margin-left: 4em;
		margin-right: 4em;
	}

	.ql-editor>p {
		padding-left: 2em !important;
		padding-right: 2em !important;
	}

	div.editor-light .ql-editor.ql-blank::before {
		/* font-size: 50px; */
		color: #787878 !important;
		font-size: 1.5em !important;
		text-indent: 1.25em !important;
		padding-left: 1.5em !important;
	
	}
	
	div.editor-dark .ql-editor.ql-blank::before {
		/* font-size: 50px; */
		color: darkgray !important;
		font-size: 1.5em !important;
		text-indent: 1.25em !important;
		padding-left: 1.5em !important;
	
	}


}


.ql-editor-light {
	color: black;
}

.ql-editor-dark {
	color: white;
}

/* Tell Quill not to scroll */
#editor {
	height: auto;
	min-height: 600px;
	padding: 50px;
}

#editor .ql-editor {
	font-sizexxxx: 18px;
	overflow-y: visible;
}

/* Specify our own scrolling container */
#scrollContainer {
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
}

.ql-font-roboto {
	font-family: 'Roboto', sans-serif;
}

.editorScroll {
	border: 0px solid #ccc;
}

.editor {
	heightx: 'none';

}

.editor .ql-container {
	height: auto;
}

.ql-editor {
	heightX: auto;
	padding: 0px 0px 0px 0px;
}

.editor li::before {
	display: 'dummy';
}


.ql-container {
	height: auto;
	text-indent: 1.25em;
}



.editor.ql-container p {
	height: auto;
	text-indent: 1.25em;
	margin-bottom: 1.0em;
	line-height: 1.5em;
	font-size: 1.5em;
}


.image4 {
	width:8em;
	height:auto;
}

/* .image4-float-left {
	float: left;
	margin-right: .5em;
	
}
.image4-float-right {
	float: right;
	
}
.image4-centered {
	width:100%;
	display: flex;
    justify-content: center;
	
}

.image4Wrapper {
	width:5em;
	height:5em;
	background-color: red;
} */

.editor.ql-container p.floatLeft {
	text-indent: 0;
	/* margin: 0em 0 0 0; */
	/* padding: 0; */
	/* text-align: center; */
	float: left;
	margin-right: .5em;
}

.editor.ql-container .floatLeft {
	text-indent: 0;
	/* margin: 0em 0 0 0; */
	/* padding: 0; */
	/* text-align: center; */
	float: left;
	margin-right: .5em;
}


.editor.ql-container p.floatRight {
	text-indent: 0;
	/* margin: 0em 0 0 0; */
	/* padding: 0; */
	/* text-align: center; */
	float: right;
	margin-left: .2em;
}

.editor.ql-container .floatRight {
	text-indent: 0;
	/* margin: 0em 0 0 0; */
	/* padding: 0; */
	/* text-align: center; */
	float: right;
	margin-left: .2em;
}





.editor.ql-container.ql-snow {
	border: 0px dashed #ccc;
	padding: 1em 0px 0px 0px;
}

.editor.ql-toolbar.ql-snow {
	border: 0px solid #ccc;
	padding: 0px 0px 0px 0px;
}

.rst__virtualScrollOverride {
	overflow-x: none;
}

.editor.ql-container ol {
	text-alignx: center;
	list-style-position: inside;
	text-indent: 0em;

}

.editor.ql-container ul {
	text-alignx: center;
	list-style-position: inside;
	text-indent: 0em;

}

.subhead {
	font-weight: bold;
	margin-bottom: 0.8em;
	text-align: left !important;
}

.decoratedBreakWrapper {
	width:100%;
	font-size:1.5rem;
}

.decoratedBreak {
	width:100%;
	text-align:center;
	font-size:1.5rem;
	text-indent:0px;
	font-family: Helvetica, Arial, sans-serif;
}

.decoratedBreakAsterik {
	margin-left:.25em;
	margin-right:.25em;
}
.versecontainer {
	text-align: center;
}

.verseblock {
	display: inline-block;
	text-align: left;

	clear: both;
	font-weight: normal;
	font-style: italic;
	text-align: left;
}

.verse {
	clear: both;
	text-indent: 0em;
	font-weight: normal;
	font-style: italic;
	text-align: left;
	margin-bottom: 1em;
}

.attribution {
	clear: both;
	font-weight: normal;
	font-style: italic;
	text-align: right;
}





blockquote {
	font-size: 1.4em;
	widthx: 60%;
	margin: 50px auto;
	font-family: Open Sans;
	font-style: italic;
	color: #555555;
	padding: 1.2em 30px 1.2em 75px;
	border-leftx: 8px solid #78C0A8;
	line-height: 1.6;
	positionx: relative;
	backgroundx: #EDEDED;
}

blockquotex::before {
	font-family: Arial;
	content: "\201C";
	color: #707070;
	font-size: 4em;
	position: absolute;
	left: -.2em;
	top: -.4em;
	margin-right: .3em;
	padding-left: .2em;
}

blockquotex::after {
	content: '';
}



.boundedblockquote {
	text-indent: 0em;
	border-topx: 1px solid #ccc;
	border-bottomx: 1px solid #ccc;
	margin-top: 1.5em;
	margin-right: 4.5em;
	margin-bottom: 1.5em;
	margin-left: 4.5em;
	padding: 0;
}

.ql-snow .ql-editor blockquote {
	border-left: 4px solid #ccc;
	margin-bottom: 5px;
	margin-top: 5px;
	padding-left: 1.5em;

}

.ql-snow .ql-editor a {
	text-decoration: unset;
}


/***** Blots *****/
.blot-wrapper-contributors {
	borderx: 2px solid red;

}

.blot-wrapper-publishers {
	borderx: 2px solid red;
}


.blot-wrapper {
	background-colorx: rgb(224, 224, 224);
	background-colorx: #009688;
	opacityx: 0.5;
	padding-top: 2em;
	padding-bottom: 2em;
	margin-top: 1em;
	margin-bottom: 1em;
	border-radiusx: 1em;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-indent: 0em;
	position: relative;
}

div.editor-light .blot-wrapper {

	border: .2em dashed #787878;

}

div.editor-dark .blot-wrapper {

	border: .2em dashed darkgray;

}

div.editor-light .blot-wrapper svg {
	fill: #787878;
}

div.editor-dark .blot-wrapper svg {
	fill: darkgray;
}

.blot-list-controls-wrapper {
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-content: center;
}

.blot-list-controls-wrapper-button {
	width: 2em;
}



.blot-list-controls-wrapper-button:hover {
    cursor: pointer;
}

.blot-footer-wrapper {
	width: 100%;
	height: 2em;
	background-colorx: lightgray;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
}

.blot-footer-section {
	flex: 4;
}

.blot-footer-section:hover {
    cursor: pointer;
}

.blot-lineitem {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: .5em .5em 5em .5em;
	heightx: 2em;
	borderx: 1px dotted blue;
	/* padding-right: 2em; */

}

.blot-lineitem-alsoby-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	/* border: 1px solid yellow; */
	align-items: center;


}

.blot-lineitem-alsoby {
	flex-direction: column;
	justify-content: center;
	/* border: 1px dotted blue; */
	align-items: center;


}

.blot-lineitem-alsoby-title {
	color: darkgray;
	font-size: 3em;
	font-family: serif;
	text-align: center;
}

.blot-lineitem-alsoby-subtitle {
	color: darkgray;
	font-size: 2em;
	font-family: serif;
	text-align: center;
}



.blot-lineitem-alsoby-thumbnail {
	height: 5em;
	width: auto;
}

.blot-lineitem-alsoby-contents {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

}

.blot-lineitem-fields {
	display: flex;
	flexx: 10;
	flex-direction: column;
	justify-contentx: space-between;

}

.blot-lineitem-controls {
	positionx: absolute;
	right: -100px;
	bottom: 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 2em;

}

.blot-content-centered {
	text-align: center;
}

div.editor-light .blot-lineitem-textarea {
	background-color: transparent;
	color: black !important;
	;
	border: 0px;
	width: 100%;
	line-height: 1.0em;
	font-size: 1.5em;
	resize: none;
	height: auto;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	/* text-align: center; */


}

div.editor-dark .blot-lineitem-textarea {
	background-color: transparent;
	color: #E5E4E2 !important;
	border: 0px;
	width: 100%;
	line-height: 1.0em;
	font-size: 1.5em;
	resize: none;
	height: auto;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	/* text-align: center; */


}

div.editor-light .blot-text {
	background-color: transparent;
	color: black !important;
	;




}

div.editor-dark .blot-text {
	background-color: transparent;
	color: #E5E4E2 !important;



}

div.editor-light .blot-lineitem-checkbox {
	accent-color: #787878;

}

div.editor-dark .blot-lineitem-checkbox {
	accent-color: darkgray;

}

div.editor-light .blot-lineitem-checkbox-wrapper {
	color: #787878;

}

div.editor-dark .blot-lineitem-checkbox-wrapper {
	color: darkgray;

}

div.editor-light .blot-lineitem-checkbox::before {
	background-color: #787878;

}

div.editor-dark .blot-lineitem-checkbox::before  {
	background-color: darkgray;

}


.blot-control-background {
	opacity:1.0;
	width:1em;
	height:1em;
	background-color:black;
	position:absolute;
	/* right:0px;
	top:0px; */
	border-radius: .5em;
}

.blot-image-caption {

	border: 0px;
	font-style: italic;
	line-height: 1.2em;
    font-size: 1.0em;
	/* width: 100%; */
	resize: none;
	height: auto;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;

}


div.editor-light .blot-image-caption {
	background-color: transparent;
	color: black !important;
	/* border: 0px; */
	/* width: 100%; */
	/* line-height: 1.0em;
	font-size: 1.5em; */
	/* resize: none;
	height: auto;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none; */
	/* text-align: center; */


}



div.editor-dark .blot-image-caption {
	background-color: transparent;
	color: #E5E4E2 !important;
	/* border: 0px; */
	/* width: 100%; */
	/* line-height: 1.0em;
	font-size: 1.5em; */
	/* resize: none;
	height: auto;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none; */
	/* text-align: center; */


}

.blot-lineitem-checkbox-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border: 0px;
	line-height: 1.0em;
	font-size: 1.5em;
	resize: none;
	height: auto;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	margin-bottom: 1.5em;

}

.blot-lineitem-checkbox {
	height: 2em;
	/* accent-color: lightgray; */
	margin-left: 1em;
	width: 1em;
	height: 1em;

}

.blot-lineitem-checkbox:hover {
	cursor: pointer;
}

.blot-lineitem-title-subtitle-div {
	display: flex;
	flex-direction: column;
}

.blot-lineitem-title-subtitle-textarea {
	font-style: italic;
}

.blot-lineitem-textarea {
	text-align: center;
}

.blot-lineitem-textarea::-webkit-scrollbar {
	width: 0 !important
}

.blot-lineitem-textarea:focus {
	outline: none !important;
	border: 0px;
}

.blot-lineitem-button {
	/* color: darkgray; */
	font-size: 1em;
	/* border: 1px solid darkgray; */
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.75;
	text-transform: uppercase;
	min-width: 64px;
	padding: 5px 15px;
	border-radius: 4px;
	cursor: pointer;
}

.blot-publisher-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.blot-blurb-content-italic {
	font-style: italic;
}

.blot-blurb-content-attribution {
	width: 100%;
	borderx: 1px solid #eee;
	text-align: right;
}

/* .blurbs-content {
	width: 75%;
} */

.blot-contributor-name {
	font-style: italic;
}


.editingDeleteBlot {

	border-topx: 2px solid red;
	border-bottom: 4px solid red;

}

.editingDeleteBlotPending {
	/* border-top:2px solid red;
	border-bottom:2px solid red; */
	colorx: red;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}

.editingDeleteBlotAccepted {

	/* border-top:2px solid red;
  border-bottom:2px solid red; */
	background-color: #c5e8d4;
	color: black;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}

.editingDeleteBlotRejected {
	/* border-top:2px solid red;
	border-bottom:2px solid red; */
	background-color: pink;
	color: black;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}


.editingDeleteBlotPreview {
	background-color: yellow;
	borderx: 3px solid green;
}

.editingInsertNewLineBlot2::after {
	content: "\a\a     ";
	white-space: pre;
}

.editingInsertNewLineBlot2Preview {
	background-color: yellow;
	/* border-top:2px solid forestgreen;
	border-bottom:2px solid forestgreen; */
}


.editingInsertNewLineBlot2Accepted {


	/* border-top:2px solid forestgreen;
	border-bottom:2px solid forestgreen; */
	background-color: #c5e8d4;
	border: 3px solid #c5e8d4;
	colorx: red;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}

.editingInsertNewLineBlot2Rejected {

	/* border-top:2px solid forestgreen;
	border-bottom:2px solid forestgreen; */
	background-color: pink;
	border: 3px solid pink;
	colorx: red;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}

.editingDeleteNewLineBlotPreview {
	background-color: yellow;
	borderx: 3px solid yellow;
}


.editingDeleteNewLineBlotAccepted {

	/* border-top:2px solid red;
	border-bottom:2px solid red; */
	background-color: #c5e8d4;
	border: 3px solid #c5e8d4;
	colorx: red;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}

.editingDeleteNewLineBlotRejected {
	/* border-top:2px solid red;
	  border-bottom:2px solid red; */
	background-color: pink;
	border: 3px solid pink;
	colorx: red;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}

.editingFormatBlot {

	text-decoration: overline;
	text-decoration-color: blue;

}

.editingFormatBlotPending {
	border-top: 2px solid red;
	border-bottom: 2px solid red;
	text-decoration: wavy;
	text-decoration-color: red;
}

.editingFormatBlotAccepted {

	border-top: 2px solid red;
	border-bottom: 2px solid red;
	background-color: #c5e8d4;
	colorx: red;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}

.editingFormatBlotRejected {
	border-top: 2px solid red;
	border-bottom: 2px solid red;
	background-color: pink;
	colorx: red;
	borderx: 1px solid red;
	text-decoration: line-through;
	text-decoration-color: red;
}


.editingFormatBlotPreview {
	background-color: yellow;
	borderx: 3px solid green;
}





.editingMovedTombstoneBlot {

	color: black;
	background-color: darkgrey;

}

.editingMovedDestinationBlot {
	colorx: yellow;
	background-colorx: tan;
	borderx: 1px dotted white;
	padding: .1em 0em .1em 0em;
	border-top: 4px solid darkgrey;
	;
	border-bottomx: 4px solid darkgrey;
	;
}

.editingInsertBlot {

	colorx: forestgreen;
	paddingx: .1em 0em .1em 0em;
	border-topx: 2px solid forestgreen;
	border-bottom: 4px solid forestgreen;

}

.editingInsertBlotPending {

	borderx: 1px solid red;

}

.editingInsertBlotAccepted {
	background-color: #c5e8d4;
	color:black;
}

.editingInsertBlotRejected {
	background-color: pink;
	color:black;
}

.editingInsertBlotPreview {
	background-color: yellow;
	borderx: 3px solid green;
}

.editingDeleteFinalViewAccepted {
	display: none;
}

.editingInsertFinalViewAccepted {}

.editingDeleteFinalViewRejected {}

.editingInsertFinalViewRejected {
	display: none;
}

.editingNewLineBlotImg {
	background-color: #c5e8d4;
}

.characterBlot {
	background-color: tan;
}

.commentBlot {
	background-color: #F7DC6F;
	border: 1px solid red;
	color:black;
}

.noteBlot {
	background-color: tan;
	border: 1px solid red;
	color:black;
}

.distraction-free-background {

	/* The image used */
	background-image: url("https://img.freepik.com/premium-photo/autumn-korea-maple-tree-park-naejangsan-national-park-autumn-season-south-korea_174052-16.jpg?w=2000");
	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	/* Add the blur effect */


	/* Full height */
	height: 100%;


	flex-grow: 1;
	height: 100vh;
	/* overflow: auto;
	position: relative; */
	z-index: 1;



}

.distraction-free-content {


	/* Full height */
	height: 100%;
	width: 100%;
	filter: blur(0px);
	-webkit-filter: blur(0px);
	z-index: 9999;
	/* https://blog.prototypr.io/how-to-make-a-background-blur-in-css-with-one-line-of-code-e446c7236e60 */
	backdrop-filter: blur(5px);

}


.tooltip-controls {
	background-color: #111;
	border-radius: 4px;
	display: none;
	/* padding: 5px 10px; */
	position: absolute;
}

/* .tooltip-controls::before {
	box-sizing: border-box;
	border-bottom: 6px solid #111;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	content: ' ';
	display: block;
	height: 6px;
	left: 50%;
	position: absolute;
	margin-left: -6px;
	margin-top: -6px;
	top: 0;
	width: 6px;
} */
.tooltip-controls-comment {
	background-color: gray;
}
.tooltip-controls button {
	background-color: transparent;
	color: #fff;
	border: none;
	width: 4em;
	height: 4em;
}

.tooltip-controls tooltip-button {
	background-color: transparent;
	color: #fff;
	border: none;
	width: 4em;
	height: 4em;
}

.tooltip-controls button.active {
	color: #21b384;
}


#sidebar-controls {
	display: none;
	position: absolute;
}

#sidebar-controls button {
	background-color: transparent;
	border: none;
	padding: 0;
}

#sidebar-controls i.fa {
	background-color: #fff;
	border: 1px solid #111;
	border-radius: 50%;
	color: #111;
	width: 32px;
	height: 32px;
	line-height: 32px;
}

#sidebar-controls .controls {
	display: none;
	margin-left: 15px;
}

#sidebar-controls #show-controls i.fa::before {
	content: "\f067";
}

#sidebar-controls.active .controls {
	display: inline-block;
}

#sidebar-controls.active #show-controls i.fa::before {
	content: "\f00d";
}

::-moz-selection { /* Code for Firefox */
	color: white;
	background: blue;
  }

::selection {
	color: white;
	background: gray;
  }

  .currentSelectionBlot {
	color: white;
	background: blue;
  }