

  
  .draggable {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0rem;
  }
  
  .draggable-category {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin: 0rem;
  }
  
  .item-wrapper {
    display: flex;
    flex-direction: column;
  }

  h2 {
    margin: 0;
  }
  
  .drag-handle {
    border: 2px solid blue;
    background: cornflowerblue;
    padding: 1rem;
    border-radius: 4px;
  }
  
  .category-container {
    width: 90%;
  }
  
  .item {
    border: 2px solid gray;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    background: rgb(157, 127, 240);
  }
  